/* generic-forms.scss */

textarea:focus,
input:focus {
    outline: none;
}

.form {
    label {
        display: block;
        font-size: 0.9em;
    }

    .input {
        background: $white;
        border: 1px solid $off-white;
        border-radius: 5px;
        clear: both;
        margin: 7px 0 10px;
        padding: 10px 15px;
        width: 100%;

        &:focus {
            border: 1px solid $blue;
            box-shadow: 1px 0 5px $black-10;
        }
    }

    textarea {
        background: $white;
        border: 1px solid $off-white;
        border-radius: 5px;
        clear: both;
        margin: 7px 0 10px;
        padding: 10px 15px;
        width: 100%;

        &:focus {
            border: 1px solid $med-grey;
            box-shadow: 1px 0 5px $black-10;
        }
    }

    .required {
        color: $red;
    }
}

.ready {
    margin: 0;

    @media (max-width: $bp-med) {
        margin-top: 20px;
    }
}

.select {
    background: $white;
    border: 1px solid $off-white;
    border-radius: 4px;
    color: $med-grey;
    display: inline-block;
    font-size: 0.8em;
    height: 44px;
    margin: 7px 0 15px;
    position: relative;
    width: 100%;

    &:before {
        background: url('../images/icon-arrow.png') no-repeat right;
        content: '';
        padding: 10px 15px;
        position: absolute;
        right: 16px;
        top: 12px;
        z-index: 12;
    }

    select {
        appearance: none;
        background: transparent;
        border: 0;
        color: $med-grey;
        font-size: 1em;
        height: 44px;
        outline: none;
        padding: 4px 25px 4px 9px;
        position: relative;
        text-indent: 1px;
        width: 100%;
        z-index: 20;
    }

    select:focus {
        border: 1px solid $black-10;
        box-shadow: 1px 0 5px $black-10;
    }
}

.field {
    label {
        clear: both;
        float: left;
    }
}
