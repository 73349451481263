/* gravity-forms.scss */

// scss-lint:disable SelectorFormat

.gform_body ul {
    padding-left: 0;

    li {
        list-style: none;

        input {
            background: $white;
            border: 1px solid $off-white;
            border-radius: 5px;
            clear: both;
            margin: 7px 0 10px;
            padding: 10px 15px;
            width: 100%;

            &:focus {
                border: 1px solid $med-grey;
            }
        }

        .ginput_container_select {
            background: $white;
            border: 1px solid $off-white;
            border-radius: 4px;
            color: $med-grey;
            display: inline-block;
            font-size: 0.8em;
            height: 44px;
            margin: 7px 0 15px;
            position: relative;
            width: 25%;
        }

        .ginput_container_select:before {
            background: url('../images/icon-arrow.png') no-repeat right;
            content: '';
            padding: 10px 15px;
            position: absolute;
            right: 16px;
            top: 12px;
            z-index: 12;
        }

        select {
            appearance: none;
            background: transparent;
            border: 0;
            color: $med-grey;
            font-size: 1em;
            height: 44px;
            outline: none;
            padding: 4px 25px 4px 9px;
            position: relative;
            text-indent: 1px;
            width: 100%;
            z-index: 20;
        }

        select:focus {
            box-shadow: 1px 0 5px $black-10;
        }
    }
}

.gform_button {
    background: inherit;
    border: 0;
    border-radius: 5px;
    color: $white;
    float: left;
    font-size: 1em;
    font-weight: 600;
    padding: 15px 40px;
    width: 100%;

    &:hover {
        color: $white;
        transition: 0.25;
    }
}

.validation_message {
    color: $red;
    font-size: 0.8em;
    margin-bottom: 20px;
}

.gform_confirmation_wrapper {
    .gform_confirmation_message {
        color: $med-grey;
        padding: 0 0 20px;
    }
}

#gform_submit_button_2 {
    float: left;
    width: 30%;

    @media (max-width: $bp-med) {
        width: 100%;
    }
}
