/* generic-buttons.scss */

.btn {
    border-radius: 5px;
    display: inline-block;
    font-size: 1em;
    overflow: hidden;
    text-align: center;

    a:hover {
        text-decoration: none;
    }
}

.small-btn {
    font-size: 0.8em;
    padding: 10px 30px;
}

.medium-btn {
    font-size: 1em;
    padding: 15px 40px;
}

.large-btn {
    font-size: 1em;
    padding: 15px 40px;
    width: 100%;
}

.wide-btn {
    padding-left: 40px;
    padding-right: 40px;
}

.border-btn {
    font-size: 1em;
    font-weight: $regular;
    transition: 0.25s;

    &:hover {
        color: $white;
        transition: 0.25s;
    }
}

.white-border {
    border: 1px solid $white;
    color: $white;
    font-size: 0.8em;
    transition: 0.25s;

    &:hover {
        background: $white-10;
        color: $white;
        transition: 0.25s;
    }
}

.dark-border {
    color: $dark-grey;
    font-size: 0.8em;
    text-transform: uppercase;
    transition: 0.1s;

    &:hover {
        color: $white;
        transition: 0.1s;
    }
}

.solid-btn {
    color: $white;
    font-size: 0.8em;
}
