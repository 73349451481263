/* generic-styles.scss */

html {
    box-sizing: border-box;
}

*,
*:before,
*:after {
    box-sizing: inherit;
}

body {
    color: $dark-grey;
    font-family: $primarytext;
    font-size: 16px;
    font-weight: normal;
    text-rendering: optimizeLegibility;
}
